import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGINNETWORKS = 'networks/SETISLOADINGINNETWORKS';
export const SETNETWORKS = 'networks/SETNETWORKS';
export const SETCURRENTNETWORK = 'networks/SETCURRENTNETWORK';
export const SETLICENSETIERS = 'networks/SETLICENSETIERS';
export const SETLICENSETYPES = 'networks/SETLICENSETYPES';

export const getNetworks = (onSuccess = () => { }, onError = () => { }) => {

  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGINNETWORKS, isLoadingNetworks: true });

    axios(`${config.endpoints.base_url}v2/networks`).then((resp) => {
      
      dispatch({ type: SETISLOADINGINNETWORKS, isLoadingNetworks: false });
      dispatch({ type: SETNETWORKS, networks: resp.data.data });
      dispatch({ type: SETCURRENTNETWORK, currentNetwork: resp.data.data[0] });
      // axios(`${config.endpoints.base_url}v1/networks/${resp.data.data.rows[0].id}`).then((resp) => {
      // });

      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const getNetworkByID = (network_id) => {
  return (dispatch, getState) => {
    if (getState().networks.networks) {
      const network = getState().networks.networks.find((network) => {
        return network.id === parseInt(network_id, 10);
      });
      if (network) {
        return network;
      }
    }
    return null;
  };
};

export const getNetworkBySiteID = (site_id) => {
  return (dispatch, getState) => {
    if (getState().sites.sites) {

      const site = getState().sites.sites.find((site) => {
        return site.id === parseInt(site_id, 10);
      });

      if (site) {
        const network = getState().networks.networks.find((network) => {
          return network.id === parseInt(site.network_id, 10);
        });

        if (network) {
          return network;
        }
      }

    }
    return null;
  };
};

export const LOGOUT = 'network/LOGOUT';

export const logoutNetwork = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
  };
};

export const getNetworkManifests = () => {
  return axios(`${config.endpoints.base_url}/network-manifests`).then((resp) => {
    return resp.data;
  }).catch((err) => {
    return err;
  });
};

export const getNetworkRequests = (params = '') => {
  return axios.get(`${config.endpoints.base_url}v3/networks?${params}`);
};

export const createNetworkManifests = () => {
  return axios.post(`${config.endpoints.base_url}/network-manifests`).then((resp) => {
    return resp.data;
  }).catch((err) => {
    return err;
  });
};

export const getLicenseTypes = (onSuccess = () => { }, onError = () => { }) => {
  return (dispatch, getState) => {
    axios(`${config.endpoints.base_url}license-types`).then((resp) => {
      dispatch({ type: SETLICENSETYPES, licenseTypes: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const getLicenseTiers = (onSuccess = () => { }, onError = () => { }) => {
  return (dispatch, getState) => {
    axios(`${config.endpoints.base_url}license-tiers`).then((resp) => {
      dispatch({ type: SETLICENSETIERS, licenseTiers: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const createNetwork = (network) => {
  return axios.post(`${config.endpoints.base_url}v4/networks`, network);
};

export const slugifyNetwork = (networkName) => {
  const data = { name: networkName };
  return axios.post(`${config.endpoints.base_url}network-code`, data);
};
